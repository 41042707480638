import {
  SINGLE_CTA_EXPERIMENT,
  SINGLE_CTA_EXPERIMENT_WO_CONTROL,
  SINGLE_CTA_PRICE_COMPARISON,
} from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useSingleCTAExperiment = () => {
  const { TREATMENT_1, TREATMENT_2, CONTROL } = SINGLE_CTA_EXPERIMENT.VARIATIONS
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: SINGLE_CTA_EXPERIMENT.EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  const {
    isEnabled: isEnabledWOControl,
    isLoading: isLoadingWOControl,
    variation: variationWOControl,
  } = useDecisionForFlag({
    name: SINGLE_CTA_EXPERIMENT_WO_CONTROL.EXPERIMENT_KEY,
    fallbackVariation: TREATMENT_1,
  })

  const { VARIANT_1: PriceVariant1, VARIANT_2: PriceVariant2 } =
    SINGLE_CTA_PRICE_COMPARISON.VARIATIONS
  const {
    isEnabled: isEnabledForPrice,
    isLoading: isLoadingForPrice,
    variation: variationForPrice,
  } = useDecisionForFlag({
    name: SINGLE_CTA_PRICE_COMPARISON.EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  const TEXT_CONSTANT = {
    ctaLabel: 'Start applying',
  }

  const isEnabledCheck = isEnabledWOControl || isEnabled
  const isTreatment1 =
    isEnabledCheck &&
    (variationWOControl === TREATMENT_1 || variation === TREATMENT_1)
  const isTreatment2 =
    isEnabledCheck &&
    (variationWOControl === TREATMENT_2 || variation === TREATMENT_2)

  // Price variants are only enabled if in treatment2 of precursor experiment
  const isPriceVariant1 = isTreatment2 && variationForPrice === PriceVariant1
  const isPriceVariant2 = isTreatment2 && variationForPrice === PriceVariant2

  return {
    isLoading: isLoadingWOControl || isLoading || isLoadingForPrice,
    isPriceEnabled: isEnabledForPrice && (isPriceVariant1 || isPriceVariant2),
    isPriceVariant1,
    isPriceVariant2,
    isTreatment1,
    isTreatment2,
    isTreatment: isEnabledCheck && (isTreatment1 || isTreatment2),
    textConstant: TEXT_CONSTANT,
  }
}
